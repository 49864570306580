if (typeof (window.StoreLocator) === "undefined") {
    window.StoreLocator = {
        config: {
            countryCode: 'AT',
            languageCode: 'de',
            queryString: null,
            initialPlaceId: null,
            displayedProduct: 'psc',
            i18n: {},
        },

        fetchPosUrl: '/index.php?eID=storeLocator',
        ratePosUrl: '/index.php?eID=storeLocatorPosFeedback',
        ratePosCookie: 'pscstorelocator',

        map: null,
        place: null,
        placeMarker: null,
        infoWindow: null,
        autocompleteService: null,
        geocoderService: null,
        geocoderResult: null,
        userGeoLocation: null,
        userAddress: null,

        timer: null,
        cachedAutocompleteRequests: [],
        posRequests: [],
        posMarkers: [],
        searchRequestInProgress: false,

        init: function(config) {
            for (let attr in config) { this.config[attr] = config[attr]; }
            this.initializeAutocompleteService();
            this.initializeGeoLocation();

            let that = this;
            if (!this.isTeaser()) {
                if (this.getDirectOnlyCheckbox()) {
                    this.getDirectOnlyCheckbox().addEventListener('change', function () {
                        if (this.getSearchFieldValue()) {
                            this.displayLocation();
                        }
                    }.bind(this));
                }

                if (config.queryString) {
                    if (config.queryString === 'mylocation') {
                        this.locateMe();
                    } else {
                        this.setSearchFieldValue(config.queryString);
                        this.displayLocation();
                    }
                }

                this.getForm().addEventListener('submit', function(e) {
                    e.preventDefault();
                    this.displayLocation();
                }.bind(this));

                document.getElementById('storelocator-filter-open').addEventListener('change', function() {
                    that.applyFilters();
                });
                document.getElementById('storelocator-filter-toprated').addEventListener('change', function() {
                    that.applyFilters();
                });
                document.getElementsByTagName('header')[0].classList.add('bg-white');
            }
        },

        initializeAutocompleteService: function () {
            this.autocompleteService = new google.maps.places.AutocompleteService;
            this.geocoderService = new google.maps.Geocoder;

            this.getSearchField().addEventListener('input', this.debounce(function(e) {
                this.hideNotFoundErrorSection();

                const searchFieldValue = this.getSearchFieldValue();
                if(searchFieldValue.length >= 2) {
                    this.getAutocompleteSuggestions(this.getAutocompleteOptions(/^\d+$/.test(this.getSearchFieldValue())), function(autocompleteResults, status) {
                        this.cacheAutocompleteResult(searchFieldValue, autocompleteResults, status);
                        if (this.searchRequestInProgress) {
                            return;
                        }
                        this.autocompletePlacePredictionsDisplayResultsCallback(autocompleteResults, status);
                    }.bind(this));
                } else {
                    this.hideAutocompleteSection();
                }
            }.bind(this), 1000));

            document.addEventListener('click', function onAutocompleteSuggestionClicked(e) {
                const suggestionClicked = e.target.closest('.pac-item');
                if (suggestionClicked) {
                    const placeId = suggestionClicked.getAttribute('data-placeid');

                    const placeDescription = suggestionClicked.querySelector('.pac-item-query').innerText;
                    this.setSearchFieldValue(placeDescription);
                    this.setPlaceIdHiddenFieldValue(placeId);
                    this.hideAutocompleteSection();

                    if (!this.isTeaser()) {
                        this.geocoderService.geocode({
                            placeId: placeId
                        }, this.geocoderCallback.bind(this));
                    }
                }
            }.bind(this));
        },

        getAutocompleteOptions: function(countryRestricted = false) {
            let autocompleteOptions = {
                input: this.getSearchFieldValue(),
                types: ['geocode']
            };
            if (countryRestricted) {
                autocompleteOptions.componentRestrictions = {country: this.config.countryCode};
            }
            return autocompleteOptions;
        },

        forceAutocomplete: function () {
            if (this.getSearchFieldValue().length > 0) {
                if (this.config.initialPlaceId) {
                    this.geocoderService.geocode({
                        placeId: this.config.initialPlaceId
                    }, this.geocoderCallback.bind(this));
                    this.config.initialPlaceId = null;
                    return;
                }
                this.getAutocompleteSuggestions(this.getAutocompleteOptions(/^\d+$/.test(this.getSearchFieldValue())), function (autocompleteResults, status) {
                    if (status === google.maps.places.PlacesServiceStatus.OK) {
                        const place = autocompleteResults[0];
                        this.setPlaceIdHiddenFieldValue(place.place_id);
                        this.geocoderService.geocode({
                            placeId: place.place_id
                        }, this.geocoderCallback.bind(this));
                    } else {
                        this.showNotFoundErrorSection();
                    }
                }.bind(this));
            } else {
                this.showNotFoundErrorSection();
            }
        },

        isTeaser: function() {
            return !!document.querySelector('.js-storelocator-teaser');
        },

        getContainer: function() {
            if (this.isTeaser()) {
                return document.querySelector('.js-storelocator-teaser');
            } else {
                return document.querySelector('.js-storelocator');
            }
        },

        getAutocompleteSuggestions: function(autocompleteOptions, autocompleteSuggestionsCallback) {
            const queryString = autocompleteOptions.input;

            if (this.searchRequestInProgress) {
                this.hideAutocompleteSection();
                return;
            }

            const cachedAutocompleteResult = this.getCachedAutocompleteResult(queryString);
            if (cachedAutocompleteResult) {
                autocompleteSuggestionsCallback(
                    cachedAutocompleteResult.autocompleteResults,
                    cachedAutocompleteResult.status
                );
                return;
            }

            this.autocompleteService.getPlacePredictions(autocompleteOptions, autocompleteSuggestionsCallback);
        },

        autocompletePlacePredictionsDisplayResultsCallback: function (autocompleteResults, status) {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
                let resultHTML = '';
                autocompleteResults.forEach(function(prediction) {
                    resultHTML +=
                        '<div class="pac-item" data-placeid="' + prediction.place_id + '">' +
                        '<i class="pre icon icon_pin"></i>' +
                        '<span class="pac-item-query">' + prediction.description + '</span>' +
                        '</div>';
                });

                if(resultHTML.length > 0){
                    this.getAutocompleteResultsContainer().innerHTML = resultHTML;
                    this.showAutocompleteSection();

                    document.addEventListener('click', function(e) {
                        if(e.target !== this.getAutocompleteSection()) {
                            this.hideAutocompleteSection();
                        }
                    }.bind(this));
                }
            } else {
                this.showNotFoundErrorSection();
            }
        },

        cacheAutocompleteResult: function(queryString, autocompleteResults, status) {
            this.cachedAutocompleteRequests.push({
                queryString: queryString,
                autocompleteResults: autocompleteResults,
                status: status
            });
        },

        getCachedAutocompleteResult: function(queryString) {
            let autocompleteRequest;
            const autocompleteRequestsLength = this.cachedAutocompleteRequests.length;
            for (let i = 0; i < autocompleteRequestsLength; i++) {
                autocompleteRequest = this.cachedAutocompleteRequests[i];
                if (autocompleteRequest.queryString === queryString) {
                    return autocompleteRequest;
                }
            }
        },

        geocoderCallback: function (geocodeResult, status) {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
                const firstResult = geocodeResult[0];
                if (this.placeIsDisplayable(firstResult)) {
                    this.setSearchFieldValue(firstResult.formatted_address);
                    this.geocoderResult = firstResult;
                    this.displayLocation();
                }
            }
        },

        placeIsDisplayable: function(place) {
            return place !== null && typeof(place) == 'object' && typeof(place.geometry) !== 'undefined';
        },

        isMobileView: function() {
            return document.getElementById('nav-tab').offsetParent !== null;
        },

        initializeMap: function() {
            if (this.map !== null) {
                return;
            }
            this.showMapSection();
            this.map = new google.maps.Map(this.getMapContainer(), {
                zoom: 17,
                disableDefaultUI: true,
                gestureHandling: 'cooperative',
                scaleControl: true,
                mapTypeId: google.maps.MapTypeId.ROADMAP
            });

            let that = this;
            google.maps.event.addListener(this.map, 'mousedown', function () {
                that.closeInfoWindow();
            });
            google.maps.event.addListener(this.map, 'dragend', function () {
                if (that.placeMarker) {
                    that.placeMarker.setMap(null);
                    that.placeMarker = null;
                }
                const center = this.getCenter();
                that.draggedLatitude = center.lat();
                that.draggedLongitude = center.lng();

                that.scheduleFetchPosByCoordinates(center.lat(), center.lng());
            });
            google.maps.event.addListener(this.map, 'resize', function () {
                if (this.isMobileView()) {
                    document.getElementById('pos-list-entry-mobile').innerHTML = document.getElementById('pos' + marker.data.posId).innerHTML;
                    document.getElementById('pos-list-entry-mobile').getElementsByClassName('js-button-row-list-view').style.display = 'none';
                    document.getElementById('pos-list-entry-mobile').getElementsByClassName('js-button-row-map-view').style.display = 'block';
                    document.getElementById('pos-list-entry-mobile').style.display = 'block';
                } else {
                    that.infoWindow.open(that.map, marker);
                    document.getElementById('pos' + marker.data.posId).scrollIntoView({block: "nearest", inline: "nearest"});
                }
            });
        },

        displayLocation: function() {
            if (!this.map) {
                this.initializeMap();
            }

            if (!this.placeIsDisplayable(this.geocoderResult)) {
                this.forceAutocomplete();
                return;
            }

            if (this.searchRequestInProgress) {
                return;
            }
            this.searchRequestInProgress = true;

            this.hideNotFoundErrorSection();
            this.showMapSection();

            this.place = this.geocoderResult;
            this.geocoderResult = null;

            if (this.place.geometry.viewport) {
                this.map.fitBounds(this.place.geometry.viewport);
            } else {
                this.map.setCenter(this.place.geometry.location);
                this.map.setZoom(17);
            }

            if (!this.placeMarker) {
                this.placeMarker = new google.maps.Marker({
                    map: this.map,
                    position: this.place.geometry.location,
                    zIndex: 9000
                });
            } else {
                this.placeMarker.setPosition(this.place.geometry.location);
            }

            this.fetchPosByPlace();
        },

        fetchPosByPlace: function () {
            this.draggedLatitude = null;
            this.draggedLatitude = null;
            this.draggedLongitude = null;

            this.hidePosResultSection();
            this.hideAutocompleteSection();
            this.clearPosResultList();
            this.clearPosMarkers();

            let httpRequest = new XMLHttpRequest();
            httpRequest.open("POST", this.fetchPosUrl);
            httpRequest.setRequestHeader("Content-Type", "application/json");
            httpRequest.send(JSON.stringify({
                search: this.queryString,
                addressComponents: this.place.address_components,
                addressGeometry: {
                    lat: this.place.geometry.location.lat(),
                    lng: this.place.geometry.location.lng()
                },
                direct: this.getDirectOnlyValue(),
                displayedProduct: this.config.displayedProduct
            }));

            this.handleHttpRequest(httpRequest, true);
        },

        scheduleFetchPosByCoordinates: function (latitude, longitude) {
            this.abortPosRequests();
            clearTimeout(this.timer);

            this.timer = setTimeout(function () {
                this.fetchPosByCoordinates(latitude, longitude);
            }.bind(this), 250);
        },

        fetchPosByCoordinates: function (latitude, longitude) {
            this.hidePosResultSection();

            let httpRequest = new XMLHttpRequest();
            httpRequest.open("POST", this.fetchPosUrl);
            httpRequest.setRequestHeader("Content-Type", "application/json");
            httpRequest.send(JSON.stringify({
                latitude: latitude,
                longitude: longitude,
                direct: this.getDirectOnlyValue(),
                displayedProduct: this.config.displayedProduct
            }));

            this.handleHttpRequest(httpRequest, false);
        },

        handleHttpRequest: function(httpRequest, isFetchPosByPlace) {
            let that = this;
            httpRequest.onreadystatechange = function() {
                if(httpRequest.readyState === 4 && httpRequest.status === 200) {
                    that.searchRequestInProgress = false;
                    that.hideAutocompleteSection();
                    that.renderResults(JSON.parse(this.responseText));

                    if (isFetchPosByPlace) {
                        that.scrollToTop();
                        that.adjustMapToMarkers();
                        if(typeof ga === 'function') {
                            const trackers = ga.getAll();
                            const firstTracker = trackers[0];
                            const trackerName = firstTracker.get('name');
                            ga(trackerName + '.send', {
                                'hitType': 'pageview',
                                'page': '/' + that.config.languageCode + '/pos-search-successful/',
                                'title': 'POS search successful',
                                'pos search term': that.getSearchFieldValue()
                            });
                        }
                    }
                }
            };
        },

        abortPosRequests: function () {
            for (let r = 0; r < this.posRequests.length; r++) {
                this.posRequests[r].abort();
            }
            this.posRequests = [];
        },

        renderResults: function (jsonResponse) {
            if (jsonResponse.status === 'success') {
                let reversedPointsOfSale = jsonResponse.data.pointsOfSale.reverse();
                for (let idx in reversedPointsOfSale) {
                    this.addPosMarker(reversedPointsOfSale[idx]);
                    this.addPosResultListEntry(reversedPointsOfSale[idx]);
                }
            }
            this.applyFilters();
        },

        addPosResultListEntry: function (markerData) {
            let listEntry = this.getFirstNonTextChild(this.getPosResultListEntryTemplate()).cloneNode(true);

            listEntry.setAttribute('data-pos-id', markerData.posId);
            listEntry.setAttribute('id', 'pos' + markerData.posId);
            if (markerData.feedbackSum > 0) {
                listEntry.querySelector('.js-pos-rating-amount').innerText = markerData.feedbackSum;
                if (markerData.feedbackAverage >= 1) {
                    listEntry.querySelector('.js-star-1-empty').style.display = 'none';
                    listEntry.querySelector('.js-star-1-full').style.display = 'inline';
                }
                if (markerData.feedbackAverage >= 2) {
                    listEntry.querySelector('.js-star-2-empty').style.display = 'none';
                    listEntry.querySelector('.js-star-2-full').style.display = 'inline';
                }
                if (markerData.feedbackAverage >= 3) {
                    listEntry.classList.add('filter-toprated');
                    listEntry.querySelector('.js-star-3-empty').style.display = 'none';
                    listEntry.querySelector('.js-star-3-full').style.display = 'inline';
                }

                listEntry.querySelector('.js-pos-rating').style.display = 'flex';
            } else {
                listEntry.classList.add('filter-toprated');
            }


            let storeImage = markerData.shopImageUrl ? markerData.shopImageUrl : markerData.posTypeLogo;
            if (storeImage === '/typo3conf/ext/pscstorelocator/Resources/Public/Images/posicon/_posicon_default_logo.svg') {
                listEntry.querySelector('.js-pos-logo').remove();
            } else {
                listEntry.querySelector('.js-pos-logo').setAttribute('src', storeImage);
            }

            listEntry.querySelector('.js-pos-header').innerText = markerData.name;

            let messageTypeClass = null;
            switch (markerData.messageType) {
                case 'INFO':
                    messageTypeClass = 'sales-outlet__available';
                    break;
                case 'PROMO':
                    messageTypeClass = 'sales-outlet__promo';
                    break;
                case 'WARN':
                    messageTypeClass = 'sales-outlet__warning';
                    break;
            }
            if (messageTypeClass !== null && markerData.message) {
                listEntry.querySelector('.js-pos-message p').innerText = markerData.message;
                listEntry.querySelector('.js-pos-message p').classList.add(messageTypeClass);
                listEntry.querySelector('.js-pos-message').style.display = 'flex';
            }

            if (markerData.products) {
                listEntry.querySelector('.js-available-products').innerText = markerData.products;
                listEntry.querySelector('.js-available-products-section').style.display = 'flex';
            }

            if (markerData.openingHours) {
                for (const day in markerData.openingHours) {
                    let dayTimes = [];
                    for (const time in markerData.openingHours[day].open) {
                        if (
                            markerData.openingHours[day].open[time].begin === '00:00' &&
                            markerData.openingHours[day].open[time].end === '00:00'
                        ) {
                            dayTimes.push(this.config.i18n['openAllDay']);
                            continue;
                        }
                        if (
                            markerData.openingHours[day].open[time].begin === 'closed' &&
                            markerData.openingHours[day].open[time].end === 'closed'
                        ) {
                            dayTimes.push(this.config.i18n['closedAllDay']);
                            continue;
                        }
                        dayTimes.push(markerData.openingHours[day].open[time].begin + ' - ' + markerData.openingHours[day].open[time].end);
                    }
                    if (dayTimes.length > 0) {
                        listEntry.querySelector('.js-open-' + day).innerText = dayTimes.join(', ');
                        listEntry.querySelector('.js-open-' + day + '-section').style.display = 'block';
                        listEntry.querySelector('.js-opening-hours-section').style.display = 'block';
                    }
                }

                if (this.isCurrentlyOpen(markerData.openingHours)) {
                    listEntry.querySelector('.js-open').style.display = 'block';
                    listEntry.classList.add('filter-open');
                } else {
                    listEntry.querySelector('.js-closed').style.display = 'block';
                    let nextOpeningHours = this.nextOpeningTime(markerData.openingHours);
                    if (nextOpeningHours) {
                        listEntry.querySelector('.js-opens-at').innerText = nextOpeningHours;
                        listEntry.querySelector('.js-opens-at-span').style.display = 'inline';
                    }

                }
            } else {
                listEntry.classList.add('filter-open');
            }

            let posText = markerData.address + '<br/>';
            posText += markerData.postalCode + ' ' + markerData.city;
            listEntry.querySelector('.js-pos-text').innerHTML = posText;

            let googleLink = "https://www.google.com/maps/dir/?api=1&destination=" + markerData.latitude + ', ' + markerData.longitude;
            listEntry.querySelector('.js-button-row-map-view .js-trigger-get-direction').href = googleLink;
            listEntry.querySelector('.js-button-row-list-view .js-trigger-get-direction').href = googleLink;

            listEntry.querySelector('.js-trigger-show-on-map').addEventListener('click', function(e) {
                e.preventDefault();
                this.highlightPosMarker(markerData.posId);
            }.bind(this));

            listEntry.querySelector('.js-trigger-rate-pos').addEventListener('click', function(e) {
                e.preventDefault();
                this.showRatingForm(markerData.posId);
            }.bind(this));

            listEntry.getElementsByClassName('js-button-row-list-view')[0].style.display = 'block';
            listEntry.getElementsByClassName('js-button-row-map-view')[0].style.display = 'none';

            listEntry.querySelector('.sales-outlet__open-collapse').setAttribute('id', 'openView' + markerData.posId);
            listEntry.querySelector('.sales-outlet__open-view').setAttribute('href', '#openView' + markerData.posId);
            listEntry.querySelector('.sales-outlet__open-view').setAttribute('aria-controls', '#openView' + markerData.posId);

            this.getPosResultList().prepend(listEntry);
            this.loadMoreButton();
        },

        isCurrentlyOpen: function(openingHours) {
            const now = new Date();
            const days = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
            const today = days[now.getDay()];
            const currentTime = `${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}`;

            if (openingHours[today]) {
                for (const timeSlot of openingHours[today].open) {
                    if (timeSlot.begin === '00:00' && timeSlot.end === '00:00') {
                        return true;
                    }
                    if (currentTime >= timeSlot.begin && currentTime <= timeSlot.end) {
                        return true;
                    }
                }
            }

            return false;
        },

        nextOpeningTime: function(openingHours) {
            const now = new Date();
            const days = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
            let todayIndex = now.getDay();
            const originalTodayIndex = todayIndex;
            const currentTime = `${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}`;

            for (let i = 0; i < 7; i++) {
                const today = days[todayIndex];
                if (openingHours[today]) {
                    for (const timeSlot of openingHours[today].open) {
                        if (String(originalTodayIndex) + currentTime <= String(todayIndex) + timeSlot.begin) {
                            return this.config.i18n[today] + ', ' + timeSlot.begin;
                        }
                    }
                }
                todayIndex = (todayIndex + 1) % 7;
            }

            return false;
        },

        loadMoreButton: function () {
            const loadMore = document.querySelector('.js-load-more');
            const elementList = document.querySelectorAll('.js-result-list .js-pos-list-entry');
            loadMore.style.display = 'block';
            for (let i = 0; i < 6; i++) {
                if (elementList[i]) {
                    elementList[i].style.display = 'block';
                }
            }
            loadMore.addEventListener('click', (e) => {
                e.preventDefault();
                for (let i = 6; i <=elementList.length; i++) {
                    if (elementList[i]) {
                        elementList[i].style.display = 'block';
                    }
                }
                loadMore.style.display = 'none';
            });
        },

        addPosMarker: function (markerData) {
            if (markerData.latitude && markerData.longitude) {
                const marker = new google.maps.Marker({
                    map: this.map,
                    position: new google.maps.LatLng(markerData.latitude, markerData.longitude),
                    icon: markerData.posMarker,
                    visible: true,
                    data: markerData
                });

                const activeIcon = {
                    url: "/typo3conf/ext/pscstorelocator/Resources/Public/Images/posicon/_posicon_default_logo_active.svg"
                };

                let that = this;
                google.maps.event.addListener(marker, 'click', function () {
                    if (that.config.displayedProduct === 'psc') {
                        that.resetMarkerIcons();
                        marker.setIcon(activeIcon);
                    }
                    if (that.infoWindow) {
                        that.infoWindow.close();
                    }
                    const data = this.data;
                    let ratingStars = '';
                    if (data.feedbackAverage > 0) {
                        ratingStars = '<div class="rating-stars">' + that.displayRatingStars(data.feedbackAverage) +
                            '<span class="feedback-sum">' + ' (' + data.feedbackSum + ')' +  '</span>' +
                            '</div>';
                    }
                    let directLoadInfo = '';
                    if (data.directload) {
                        directLoadInfo = '<br /><a href="/paysafecard-direct/" style="font-size:25px;line-height:25px;" target="_blank" title="paysafecard direct"><i class="icon icon_directload-transaction"></i></a>';
                    }
                    that.infoWindow = new google.maps.InfoWindow({
                        maxWidth: 600,
                        content: '<div class="map-infowindow">' +
                            '<span>' + data.name + '</span>' +
                            '<br/>' +
                            '<div id="map-infowindow-body-content">' +
                            data.address +
                            '<br />' +
                            data.postalCode + ' ' + data.city + directLoadInfo +
                            '</div>' +
                            '</div>' +
                            ratingStars
                    });

                    document.getElementById('pos-list-entry-mobile').innerHTML = document.getElementById('pos' + marker.data.posId).innerHTML;
                    document.getElementById('pos-list-entry-mobile').getElementsByClassName('js-button-row-list-view')[0].style.display = 'none';
                    document.getElementById('pos-list-entry-mobile').getElementsByClassName('js-button-row-map-view')[0].style.display = 'block';
                    document.getElementById('pos-list-entry-mobile').querySelector('.js-trigger-rate-pos').addEventListener('click', function(e) {
                        e.preventDefault();
                        that.showRatingForm(marker.data.posId);
                    });

                    if (document.getElementById('nav-tab').offsetParent === null) {
                        that.infoWindow.open(that.map, marker);
                        document.getElementById('pos' + marker.data.posId).scrollIntoView({block: "nearest", inline: "nearest"});
                        let otherPosListEntries = document.getElementsByClassName('js-pos-list-entry');
                        let otherPosListEntriesLength = otherPosListEntries.length;
                        for (let i = 0; i < otherPosListEntriesLength; i++) {
                            otherPosListEntries[i].classList.remove('js-pos-list-entry-active');
                        }
                        document.getElementById('pos' + marker.data.posId).classList.add('js-pos-list-entry-active');
                    } else {
                        document.getElementById('pos-list-entry-mobile').style.display = 'block';
                    }
                });

                this.posMarkers.push(marker);
            }
        },

        displayRatingStars: function (averageRatingValue) {
            let ratingHtml = '';
            for (let i = 1; i <= 3; i++) {
                if (averageRatingValue >= i) {
                    ratingHtml += '<span class="star star--full"></span>';
                } else {
                    ratingHtml += '<span class="star star--empty"></span>';
                }
            }
            ratingHtml +='</div>';
            return ratingHtml;
        },

        highlightPosMarker: function (posId) {
            this.closeInfoWindow();
            window.scrollTo(0, 0);

            const markerCount = this.posMarkers.length;
            for (let i = 0; i < markerCount; i++) {
                if (this.posMarkers[i].data.posId === posId) {
                    this.posMarkers[i].setAnimation(google.maps.Animation.BOUNCE);
                    this.posMarkers[i].setZIndex(9999);

                    const bouncingMarker = this.posMarkers[i];
                    const latLng = bouncingMarker.getPosition(); // returns LatLng object
                    this.map.setCenter(latLng);
                    setTimeout(function () {
                        bouncingMarker.setAnimation(null);
                    }, 2900);
                }
            }
            return false;
        },

        getPosMarker: function (posId) {
            const markerCount = this.posMarkers.length;
            for (let i = 0; i < markerCount; i++) {
                if (this.posMarkers[i].data.posId === posId) {
                    return this.posMarkers[i];
                }
            }
        },

        clearPosMarkers: function () {
            const markerCount = this.posMarkers.length;
            for (let i = 0; i < markerCount; i++) {
                this.posMarkers[i].setMap(null);
            }
            this.posMarkers = [];
        },

        adjustMapToMarkers: function () {
            const markerCount = this.posMarkers.length;
            if (markerCount > 0) {
                let bounds = new google.maps.LatLngBounds();
                if (this.placeMarker) {
                    bounds.extend(this.placeMarker.getPosition());
                }
                for (let i = 0; i < markerCount; i++) {
                    bounds.extend(this.posMarkers[i].getPosition());
                }

                this.map.fitBounds(bounds);
                if (this.map.getZoom() > 17) {
                    this.map.setZoom(17);
                }
            }
        },

        showRatingForm: function (posId) {
            let ratingForm = this.getFirstNonTextChild(this.getPosRatingFormTemplate()).cloneNode(true);
            ratingForm.setAttribute('data-pos-id', posId);

            let listEntry = this.getPosResultListEntryForPos(posId);
            listEntry.parentNode.insertBefore(ratingForm, listEntry.nextSibling);

            document.getElementById('pos-list-entry-mobile').innerHTML = ratingForm.innerHTML;

            listEntry.style.display = 'none';
            ratingForm.style.display = 'block';

            let container = [ratingForm, document.getElementById('pos-list-entry-mobile')];
            for (let i = 0; i < container.length; i++) {
                container[i].querySelector('.js-trigger-rating-cancel').addEventListener('click', function(e) {
                    e.preventDefault();
                    this.getPosRatingFormForPos(posId).style.display = 'none';
                    this.getPosResultListEntryForPos(posId).style.display = 'block';

                    let that = this;
                    document.getElementById('pos-list-entry-mobile').innerHTML = document.getElementById('pos' + posId).innerHTML;
                    document.getElementById('pos-list-entry-mobile').getElementsByClassName('js-button-row-list-view')[0].style.display = 'none';
                    document.getElementById('pos-list-entry-mobile').getElementsByClassName('js-button-row-map-view')[0].style.display = 'block';
                    document.getElementById('pos-list-entry-mobile').querySelector('.js-trigger-rate-pos').addEventListener('click', function(e) {
                        e.preventDefault();
                        that.showRatingForm(posId);
                    });
                }.bind(this));

                container[i].querySelector('.js-trigger-rating-send').addEventListener('click', function(e) {
                    e.preventDefault();
                    let invalidFeedback = document.querySelector(".js-feedback");
                    let ratingChecked = document.querySelectorAll('input[name="salesOutletRatingRadio"]:checked');

                    if (ratingChecked.length < 1)
                    {
                        invalidFeedback.style.display = 'block';
                    }
                    else {
                        invalidFeedback.style.display = 'none';
                        this.ratePos(posId, ratingForm.querySelector('input[name="salesOutletRatingRadio"]:checked').value);
                    }
                }.bind(this));
            }
        },

        showRatingDone: function (posId) {
            this.getPosRatingFormForPos(posId).remove();

            let ratingDone = this.getFirstNonTextChild(this.getPosRatingDoneTemplate()).cloneNode(true);
            ratingDone.setAttribute('data-pos-id', posId);

            let listEntry = this.getPosResultListEntryForPos(posId);
            listEntry.parentNode.insertBefore(ratingDone, listEntry.nextSibling);

            document.getElementById('pos-list-entry-mobile').innerHTML = ratingDone.innerHTML;

            listEntry.style.display = 'none';
            ratingDone.style.display = 'block';

            let container = [ratingDone, document.getElementById('pos-list-entry-mobile')];
            for (let i = 0; i < container.length; i++) {
                container[i].querySelector('.js-trigger-rating-done').addEventListener('click', function (e) {
                    e.preventDefault();
                    this.getPosRatingDoneForPos(posId).remove();
                    this.getPosResultListEntryForPos(posId).style.display = 'block';

                    document.getElementById('pos-list-entry-mobile').innerHTML = document.getElementById('pos' + posId).innerHTML;
                    document.getElementById('pos-list-entry-mobile').getElementsByClassName('js-button-row-list-view')[0].style.display = 'none';
                    document.getElementById('pos-list-entry-mobile').getElementsByClassName('js-button-row-map-view')[0].style.display = 'block';
                }.bind(this));
            }
        },

        ratePos: function (posId, rating) {
            this.showRatingDone(posId);

            let that = this;
            fetch(this.ratePosUrl, {
                method: "POST",
                body: JSON.stringify({
                    posId: posId,
                    rating: rating,
                    countryCode: this.config.countryCode,
                    languageCode: this.config.languageCode
                })
            })
                .then(function(res){ that.writePosCookie(posId) });
        },

        writePosCookie: function (posId) {
            let cookieValue = posId + '|';
            let currentCookie = this.readPosCookie();
            if (currentCookie.indexOf(cookieValue) === -1) {
                let date = new Date();
                date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000));
                const expires = "; expires=" + date.toGMTString();
                document.cookie = this.ratePosCookie + '=' + currentCookie + cookieValue + expires + "; path=/";
            }
        },

        readPosCookie: function () {
            const cookiePrefix = this.cookieName + "=";
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                let cookie = cookies[i];
                while (cookie.charAt(0) === ' ') {
                    cookie = cookie.substring(1, cookie.length);
                }
                if (cookie.indexOf(cookiePrefix) === 0) {
                    return cookie.substring(cookiePrefix.length, cookie.length);
                }
            }
            return '';
        },

        initializeGeoLocation: function () {
            if (!('geolocation' in navigator)) {
                this.getLocateMeButton().css.visibility = 'hidden';
                return;
            }

            let that = this;
            this.getLocateMeButton().addEventListener('click', function(e) {
                e.preventDefault();
                that.locateMe();
            });
        },

        locateMe: function () {
            if (!this.isTeaser()) {
                this.initializeMap();
            }

            navigator.geolocation.getCurrentPosition(
                function (position) {
                    this.userGeoLocation = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
                    if (!this.userGeoLocation) {
                        return;
                    }

                    this.geocoderService.geocode({'location': this.userGeoLocation}, function (results, status) {
                        if (status === 'OK' && results[0]) {
                            if (results[0]) {
                                this.geocoderResult = results[0];

                                this.userAddress = results[0].formatted_address;
                                this.setSearchFieldValue(this.userAddress);
                                this.setPlaceIdHiddenFieldValue(results[0].place_id);

                                if (this.isTeaser()) {
                                    return;
                                }

                                if (this.userLocationMarker == null) {
                                    this.userLocationMarker = new google.maps.Marker({map: this.map})
                                }

                                this.queryString = null;
                                this.draggedLatitude = null;
                                this.draggedLongitude = null;

                                this.userLocationMarker.setPosition(this.userGeoLocation);
                                if (!this.placeMarker) {
                                    this.placeMarker = new google.maps.Marker({
                                        map: null,
                                        position: this.userGeoLocation
                                    });
                                } else {
                                    this.placeMarker.setPosition(this.userGeoLocation);
                                }

                                this.displayLocation();
                            } else {
                                console.err('Error Finding Address');
                            }
                        } else {
                            console.err('Geocoder failed due to:', status);
                        }
                    }.bind(this));
                }.bind(this),
                function (err) {
                    console.log(err);
                    this.showNotFoundErrorSection();
                }.bind(this),
                {
                    timeout: 101000
                }
            );
        },

        applyFilters: function() {
            let showOpen = document.getElementById('storelocator-filter-open').checked;
            let showTopRated = document.getElementById('storelocator-filter-toprated').checked;

            const entries = document.querySelectorAll('.js-result-list .js-pos-list-entry');
            for (let i = 0; i < entries.length; i++) {
                let entry = entries[i];
                let entryMarker = this.getPosMarker(parseInt(entry.dataset.posId));
                if (
                    (!showOpen || entry.classList.contains('filter-open')) &&
                    (!showTopRated || entry.classList.contains('filter-toprated'))
                ) {
                    entry.style.display = 'block';
                    if (entryMarker) {
                        entryMarker.setMap(this.map);
                    }
                } else {
                    entry.style.display = 'none';
                    if (entryMarker) {
                        entryMarker.setMap(null);
                    }
                }
            }
        },

        getForm: function() {
            return this.getContainer().querySelector('.js-form');
        },

        getMapContainer: function() {
            return this.getContainer().querySelector('.js-map');
        },

        getSearchField: function() {
            return this.getContainer().querySelector('.js-search-field');
        },

        getSearchFieldValue: function() {
            return this.getSearchField().value;
        },

        setSearchFieldValue: function(value) {
            this.getSearchField().value = value;
        },

        getPlaceIdHiddenField: function() {
            return this.getContainer().querySelector('.js-place-id-hidden-field');
        },

        setPlaceIdHiddenFieldValue: function(value) {
            this.getPlaceIdHiddenField().value = value;
        },

        getAutocompleteSection: function() {
            return this.getContainer().querySelector('.js-autocomplete-section');
        },

        showAutocompleteSection: function() {
            return this.getAutocompleteSection().style.display = 'block';
        },

        hideAutocompleteSection: function() {
            return this.getAutocompleteSection().style.display = 'none';
        },

        getAutocompleteResultsContainer: function() {
            return this.getContainer().querySelector('.js-autocomplete-results');
        },

        getNotFoundErrorSection: function() {
            return this.getContainer().querySelector('.js-location-not-found');
        },

        showNotFoundErrorSection: function() {
            this.geocoderResult = null;
            this.place = null;
            let jsMap = document.querySelector('.js-storelocator .js-map > div');
            if(jsMap)
            {
                jsMap.classList.add("js-search-not-found");
            }
            return this.getNotFoundErrorSection().style.display = 'block';
        },

        hideNotFoundErrorSection: function() {
            let jsMap = document.querySelector('.js-storelocator .js-map > div');
            if(jsMap)
            {
                jsMap.classList.remove("js-search-not-found");
            }
            return this.getNotFoundErrorSection().style.display = 'none';
        },

        hidePosResultSection: function() {
        },

        getPosResultListEntryTemplate: function() {
            return this.getContainer().querySelector('.js-pos-list-entry-template');
        },

        getPosResultListEntryForPos: function(posId) {
            return this.getContainer().querySelector('.js-pos-list-entry[data-pos-id="' + posId + '"]');
        },

        getPosRatingFormTemplate: function() {
            return document.querySelector('.js-pos-rating-form-template');
        },

        getPosRatingFormForPos: function(posId) {
            return this.getContainer().querySelector('.js-pos-rating-form[data-pos-id="' + posId + '"]');
        },

        getPosRatingDoneTemplate: function() {
            return document.querySelector('.js-pos-rating-done-template');
        },

        getPosRatingDoneForPos: function(posId) {
            return this.getContainer().querySelector('.js-pos-rating-done[data-pos-id="' + posId + '"]');
        },

        getPosResultList: function() {
            return this.getContainer().querySelector('.js-result-list');
        },

        clearPosResultList: function() {
            const parent =  this.getPosResultList()
            while (parent.firstChild) {
                parent.removeChild(parent.firstChild);
            }
        },

        showMapSection: function () {
            document.querySelector('.js-storelocator .js-header-text').style.visibility = 'hidden';
            document.querySelector('.js-storelocator .js-map-section').classList.add("show-map-section");
            document.querySelector('.js-storelocator').classList.add('storelocator__active');
        },

        getLocateMeButton: function() {
            return this.getContainer().querySelector('.js-locate-me-button');
        },

        getDirectOnlyCheckbox: function() {
            return this.getContainer().querySelector('input.js-direct-only-checkbox');
        },

        getDirectOnlyValue: function() {
            return this.getContainer().querySelector('input.js-direct-only-checkbox:checked') !== null;
        },

        resetMarkerIcons: function() {
            const markerCount = this.posMarkers.length;
            for (let i = 0; i < markerCount; i++) {
                this.posMarkers[i].setIcon(this.posMarkers[i].data.posMarker);
            }
        },

        closeInfoWindow: function () {
            if (this.infoWindow) {
                this.infoWindow.close();
            }
            this.resetMarkerIcons();
            document.getElementById('pos-list-entry-mobile').innerHTML = '';
            document.getElementById('pos-list-entry-mobile').style.display = 'none';
        },

        debounce: function (func, wait, immediate) {
            let timeout, args, context, timestamp, result;

            const later = function() {
                let last = Date.now() - timestamp;

                if (last < wait && last >= 0) {
                    timeout = setTimeout(later, wait - last);
                } else {
                    timeout = null;
                    if (!immediate) {
                        result = func.apply(context, args);
                        if (!timeout) context = args = null;
                    }
                }
            };

            return function() {
                context = this;
                args = arguments;
                timestamp = Date.now();
                let callNow = immediate && !timeout;
                if (!timeout) timeout = setTimeout(later, wait);
                if (callNow) {
                    result = func.apply(context, args);
                    context = args = null;
                }

                return result;
            };
        },

        getFirstNonTextChild: function (el) {
            let firstChild = el.firstChild;
            while(firstChild != null && firstChild.nodeType === 3){
                firstChild = firstChild.nextSibling;
            }
            return firstChild;
        },

        scrollToTop: function()
        {
            window.scroll({top: 0, left: 0, behavior: 'smooth'});
        }
    };

    window.StoreLocatorInit = window.StoreLocator.init.bind(StoreLocator);
}
